import { wrap } from '@popmotion/popcorn';
import React, { PropsWithChildren, useCallback, useMemo, useReducer, useState } from 'react';
import {
    animate,
    motion, PanInfo, Transition, useMotionValue,
} from 'framer-motion';
import useResizeObserver from '@react-hook/resize-observer';
import FadeTransitionFramer from '../../animations/transitions/fade-transition-framer';

interface SliderContext {
    page: number
    direction: number
    paginate: (newDirection: number) => void
    jumpTo: (index: number) => void
}

const SliderContext = React.createContext<SliderContext>(null as unknown as SliderContext);

interface NotchedSlider {
    children: React.ReactNode|React.ReactNode[]
    autoPlay?: number
}

const NotchedSlider = ({ children, autoPlay }: NotchedSlider): JSX.Element => {
    const [[page, direction], setPage] = useState([0, 0]);

    const paginate = useCallback((newDirection: number) => {
        setPage([page + newDirection, newDirection]);
    }, [page]);

    const jumpTo = useCallback((newPage: number) => {
        setPage([newPage, 1]);
    }, []);

    React.useEffect(() => {
        let timer: NodeJS.Timeout;
        if (autoPlay) {
            timer = setTimeout(() => {
                paginate(1);
            }, autoPlay * 1000);
        }
        return () => {
            clearTimeout(timer);
        };
    }, [autoPlay, paginate, page]);

    const value = useMemo(() => ({ page, direction, paginate, jumpTo }), [page, direction, paginate, jumpTo]);

    return (
        <SliderContext.Provider value={value}>
            {children}
        </SliderContext.Provider>
    );
};

export const useSliderContext = (): SliderContext => React.useContext(SliderContext);

const SliderLeftButton = (props: PropsWithChildren): JSX.Element => {
    const { paginate } = useSliderContext();
    return React.cloneElement(props.children, { ...props, onClick: () => paginate(-1) });
};

const SliderRightButton = (props: PropsWithChildren): JSX.Element => {
    const { paginate } = useSliderContext();
    return React.cloneElement(props.children, { ...props, onClick: () => paginate(1) });
};

const JumpToSlide = ({ index, ...props }: PropsWithChildren<{ index: number }>): JSX.Element => {
    const { jumpTo } = useSliderContext();
    return React.cloneElement(props.children, { ...props, onClick: () => jumpTo(index) });
};

interface Slides {
    children: React.ReactNode[]
    className?: string
    slidesPerView?: number
    centered?: boolean
    overflowVisible?: boolean
    masksLeft?: boolean
    masksRight?: boolean
}

const transition: Transition = {
    type: 'spring',
    bounce: 0,
};

/*
* This slider is not responsive by breakpoints as options like centering can not be predictably rendered on SSR.
* Instead - where responsive sliders are needed (i.e. with different slidesPerView on Mob) - put multiple implementations
* of <NotchedSlider.Slides /> inside a single <NotchedSlider /> (the context).
* */

const Slides = ({
    children,
    className,
    slidesPerView = 1,
    centered,
    overflowVisible,
    masksLeft,
    masksRight,
}: Slides): JSX.Element => {
    const { page, paginate } = useSliderContext();
    const [activated, setActivated] = React.useState(false);

    const slides = React.Children.toArray(children);

    const scrollRef = React.useRef<HTMLDivElement>(null);

    const [{ height: minHeight, width: minWidth }, setMaxDimensions] = useReducer((
        prev: { height: number, width: number, heightIndex: number, widthIndex: number },
        action: { element: HTMLDivElement | null, index: number },
    ) => {
        /*
        * Screen out 0 index here because it's the 'relative'
        * positioned one and thus the height will always be the
        * container's. The over-scanning gives us the actual height
        * of slide 0 - just rendered later in process.
        *
        * We check if the index is lesser than the last saved to
        * enable resize-observer functionality - this way if page
        * is resized, the slides call setMaxWidth in order and we
        * can work out whether this is a distinct 'event'.
        * */

        if (action.index === 0) {
            return {
                height: 0,
                width: 0,
                heightIndex: 0,
                widthIndex: 0,
            };
        }

        if (action.element && action.index > 0) {
            const updates = {
                height: prev.height,
                heightIndex: prev.heightIndex,
                width: prev.width,
                widthIndex: prev.widthIndex,
            };

            const { height } = action.element.getBoundingClientRect();

            if (prev?.height < height || action.index <= prev.heightIndex) {
                updates.height = height;
                updates.heightIndex = action.index;
            }

            // Width needs to come from children, because the slide ref has width applied (unlike height)
            if (action.element.firstElementChild) {
                const { width } = action.element.firstElementChild.getBoundingClientRect();
                if (prev?.width < width || action.index <= prev.widthIndex) {
                    updates.width = width;
                    updates.widthIndex = action.index;
                }
            }

            return updates;
        }
        return prev;
    }, { height: 0, width: 0, heightIndex: 0, widthIndex: 0 });

    const x = useMotionValue(0);
    const containerRef = React.useRef<HTMLDivElement>(null);
    // Directly maps to page in context, is any number

    const newX = (): number => (-page * (containerRef.current?.clientWidth || 0)) / slidesPerView;

    const handleEndDrag = (e: Event, dragProps: PanInfo): void => {
        e.preventDefault();
        e.stopPropagation();
        if (containerRef.current) {
            containerRef.current.classList.remove('dragging');
        }
        if (dragProps) {
            const clientWidth = containerRef.current?.clientWidth || 0;

            const { offset, velocity } = dragProps;

            if (Math.abs(velocity.y) > Math.abs(velocity.x)) {
                animate(x, newX(), transition);
                return;
            }

            if (offset.x > clientWidth / 4 / slidesPerView) {
                // console.log('was a', offset.x / (clientWidth / totalSlidesAmount) / 2);
                paginate(-1);
            } else if (offset.x < -clientWidth / 4 / slidesPerView) {
                // console.log('was b');
                paginate(1);
            } else {
                animate(x, newX(), transition);
            }
        }
    };

    useResizeObserver<HTMLDivElement>(scrollRef, (entry) => {
        if (entry) {
            const animation = animate(x, newX(), transition);
            animation.stop();
            setActivated(false);
        }
    });

    const centerSlideOffset = centered ? Math.ceil(slidesPerView / 2) : 0;

    React.useEffect(() => {
        // console.log("run update", page, x, newX(), transition)
        if (!activated) setActivated(true);
        const controls = animate(x, newX(), transition);
        return controls.stop;
    }, [page, activated]);

    const range = React.useMemo(() => [
        ...(new Array((slidesPerView * 2) + 4
            + (slidesPerView % 2 === 0 ? 0 : 1))),
    ].map((_, idx) => idx - (slidesPerView + 2)), [slidesPerView, centerSlideOffset]);

    return (
        <div
            ref={scrollRef}
            role="list"
            onClick={() => {
                if (!activated) setActivated(true);
            }}
            css={{
                width: '100%',
                position: 'relative',
                display: 'flex',
                overflow: overflowVisible ? undefined : 'hidden',
                userSelect: 'none',
            }}
            className={className}
            style={{ minHeight: activated ? `${minHeight}px` : undefined }}
        >
            <motion.div
                ref={containerRef}
                css={{
                    width: '100%',
                    display: 'flex',
                    flexFlow: 'row nowrap',
                    flex: 'none',
                    transform: 'none!important',
                }}
            >
                {range.map((rangeValue, idx) => {
                    const index = rangeValue + page;
                    const modulo = index % slides.length;
                    const imageIndex = modulo < 0 ? slides.length + modulo : modulo;
                    const shouldRender = minHeight !== 0 && activated;

                    const shouldMaskLeft = masksLeft && rangeValue < 0;
                    const shouldMaskRight = masksRight && rangeValue > (slidesPerView - 1);

                    return (
                        <motion.div
                            key={index}
                            ref={e => setMaxDimensions({ element: e, index })}
                            style={{
                                position: shouldRender ? 'absolute' : 'relative',
                                width: `${100 / slidesPerView}%`,
                                minWidth: `${100 / slidesPerView}%`,
                                maxWidth: `${100 / slidesPerView}%`,
                                height: '100%',
                                x,
                                left: shouldRender ? `${index * (100 / slidesPerView)}%` : undefined,
                                marginLeft: (!shouldRender && idx === 0) ? `${index * (100 / slidesPerView)}%` : undefined,
                                right: shouldRender ? `${index * (100 / slidesPerView)}%` : undefined,
                            }}
                            draggable
                            drag="x"
                            dragElastic={1}
                            onDragEnd={handleEndDrag}
                            onDragStart={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                if (containerRef?.current) {
                                    containerRef.current.classList.add('dragging');
                                }
                            }}
                        >
                            <span
                                css={{
                                    '.dragging &': {
                                        pointerEvents: 'none',
                                    },
                                    opacity: (shouldMaskLeft || shouldMaskRight) ? 0 : 1,
                                    transition: 'opacity 0.25s',
                                }}
                                data-active={
                                    centered ? rangeValue - centerSlideOffset + 1 === 0 : rangeValue === 0
                                }
                            >
                                {slides[wrap(0, slides.length, centered ? imageIndex - centerSlideOffset + 1 : imageIndex)]}
                            </span>
                        </motion.div>
                    );
                })}
            </motion.div>
        </div>
    );
};

const Fade = ({ children, className }: PropsWithChildren<{ className?: string }>): JSX.Element => {
    const { page, paginate } = useSliderContext();
    const [activated, setActivated] = React.useState(false);
    const slides = React.Children.toArray(children);

    const focusedSlide = slides[wrap(0, slides.length, page)];

    return (
        <div
            role="list"
            onClick={() => {
                if (!activated) setActivated(true);
            }}
            css={{
                width: '100%',
                position: 'relative',
                display: 'flex',
                overflow: 'hidden',
                userSelect: 'none',
            }}
            className={className}
        >
            <FadeTransitionFramer shouldChange={page.toString()}>
                {focusedSlide}
            </FadeTransitionFramer>
        </div>
    );
};

interface PaginationProps {
    slideCount: number
    children: ({ onClick, index }: { onClick: () => void, index: number, currentIndex: number }) => JSX.Element
}
const Pagination = ({ slideCount, children }: PaginationProps): JSX.Element[] => {
    const { page, jumpTo } = useSliderContext();
    const slideIndex = wrap(0, slideCount, page);
    return [...new Array(slideCount)].map((_, idx) => children({
        onClick: () => {
            jumpTo(idx);
        },
        index: idx,
        currentIndex: slideIndex,
    }));
};

NotchedSlider.Slides = Slides;
NotchedSlider.SliderLeftButton = SliderLeftButton;
NotchedSlider.SliderRightButton = SliderRightButton;
NotchedSlider.Pagination = Pagination;
NotchedSlider.JumpToSlide = JumpToSlide;
NotchedSlider.Fade = Fade;
export default NotchedSlider;
